<template>
  <cui-acl  :roles="['underDevelopment']" redirect>
    <div v-if="loaded">
      <!-- <div class="card">
      <div class="card-body"> -->
        <h4>Mine oppgaver</h4>
        <list :list="tasks" target="tasks" />
        <div v-if="tasks.length == 0">
          <div class="no-tasks">
            <i class="fa fa-rocket"></i>
            <h5>Hurra! Du har ingen oppgaver gjenstående!</h5>
          </div>
        </div>
      <!-- </div>
      </div> -->
    </div>
  </cui-acl>
</template>

<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import firebase from '@/services/firebase'
import list from '@/components/custom/visual/lists/List1'

export default {
  components: {
    list,
    cuiAcl,
  },
  data() {
    return {
      tasks: [],
      loaded: false,
      taskRef: '',
    }
  },
  mounted() {
    this.taskRef = firebase.firestore.collection('tasks').where('status', '==', 'done').orderBy('date', 'desc')
    this.taskRef
      .onSnapshot(snapshot => {
        this.tasks = []
        snapshot.forEach(doc => {
          if (['tasksAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0) || [doc.data().type].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
            this.tasks.push({
              title: doc.data().title,
              type: doc.data().type,
              date: doc.data().date,
              icon: doc.data().icon,
              id: doc.id,
            })
          }
        })
        this.loaded = true
      })
  },
}
</script>

<style>
  .no-tasks {
    margin-top: 35vh;
    text-align: center;
  }
  .no-tasks i {
    font-size: 38px;
    margin-bottom: 15px;
  }
</style>
